// ./components/AfrCalculator.tsx

import React from 'react';
import useAfrCalculator from '../hooks/UseAfrCalculator';
import { Button, FormControl, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check'
import useAnalyticsEventTracker from '../hooks/UseAnalyticsEventTracker';

const AfrCalculator = () => {
  const {
    lambdaValue,
    afrValue,
    setLambdaValue,
    setAfrValue,
    convertToAfr,
    convertToLambda,
  } = useAfrCalculator();

  const gaEventTracker = useAnalyticsEventTracker('ArfCalculator');
  gaEventTracker('pageLoad');
  
  const localConvertToAfr = () => {
    gaEventTracker('convertToAfr');
    convertToAfr();
  }

  const localConvertToLambda = () => {
    gaEventTracker('convertToLambda');
    convertToLambda();
  }

  return (
    <div className="App">
      <div>Air Fuel Ratio</div>
      <p>
        The Stoichiometric AFR for gasoline is typically 14.7 (parts of air to fuel). Meaning, when Lambda = 1, the AFR should be 14.7.<br />
      </p>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          type="number"
          value={lambdaValue}
          onChange={e => setLambdaValue(Number.parseFloat(e.target.value))}
          placeholder="Lambda value"
          label="Lambda value"
        />
      </FormControl><br/>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <Button variant="outlined" color="primary" aria-label="Calculate AFR" size="large" startIcon={<CheckIcon fontSize="inherit" />} onClick={localConvertToAfr}>Calculcate AFR</Button>
      </FormControl><br/>
      <br/>
      <br/>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          type="number"
          value={afrValue}
          onChange={e => setAfrValue(Number.parseFloat(e.target.value))}
          placeholder="AFR value"
          label="AFR value"
        />
      </FormControl><br/>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <Button variant="outlined" color="primary" aria-label="Calculate Lambda" size="large" startIcon={<CheckIcon fontSize="inherit" />} onClick={localConvertToLambda}>Calculcate Lambda</Button>
      </FormControl><br/>
    </div>
  );
};

export default AfrCalculator;