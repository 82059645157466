// ./components/EffectiveGearRatioCalculator.tsx

import React from 'react';
import useEffectiveGearRatioCalculator from '../hooks/UseEffectiveGearRatioCalculator';
import { FormControl, TextField, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check'

const EffectiveGearRatioCalculator = () => {
  const {
    originalTireDiameter,
    newTireDiameter,
    actualGearRatio,
    effectiveGearRatio,
    setOriginalTireDiameter,
    setNewTireDiameter,
    setActualGearRatio,
    calculateEffectiveGearRatio,
  } = useEffectiveGearRatioCalculator();

  return (
    <div className="App">
      <div>Effective Gear Ratio Calculator</div>
      <p>
        Effective Gear Ratio can be calculated using the formula: (original tire diameter / new tire diameter) x actual gear ratio
      </p>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          type="number"
          value={originalTireDiameter}
          onChange={e => setOriginalTireDiameter(Number.parseFloat(e.target.value))}
          placeholder="Original Tire Diameter"
          label="Original Tire Diameter"
        />
      </FormControl><br/>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          type="number"
          value={newTireDiameter}
          onChange={e => setNewTireDiameter(Number.parseFloat(e.target.value))}
          placeholder="New Tire Diameter"
          label="New Tire Diameter"
        />
      </FormControl><br/>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          type="number"
          value={actualGearRatio}
          onChange={e => setActualGearRatio(Number.parseFloat(e.target.value))}
          placeholder="Actual Gear Ratio"
          label="Actual Gear Ratio"
        />
      </FormControl><br/>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <Button variant="outlined" color="primary" aria-label="Calculate Effective Gear Ratio" size="large" startIcon={<CheckIcon fontSize="inherit" />} onClick={calculateEffectiveGearRatio}>Calculate Effective Gear Ratio</Button>
      </FormControl><br/>
      {effectiveGearRatio > 0 && <p>Effective Gear Ratio: {effectiveGearRatio}</p>}
    </div>
  );
};

export default EffectiveGearRatioCalculator;