// ./hooks/UseElectricityCalculator.tsx

import { useState } from 'react';

const useElectricityCalculator = () => {
  const [voltage, setVoltage] = useState('');
  const [current, setCurrent] = useState('');
  const [resistance, setResistance] = useState('');
  const [power, setPower] = useState('');
  const [fuseSize, setFuseSize] = useState('');

  const calculateFuseSize = () => {
    const computedFuseSize = Math.round((Number.parseFloat(current) * 1.20) / 5) * 5;
    setFuseSize(computedFuseSize.toFixed(0));
  };

  const calculateAny = () => {
    const hasVoltage = (voltage > '');
    const hasCurrent = (current > '');
    const hasResistance = (resistance > '');
    const hasPower = (power > '');
    
    if(hasVoltage && hasCurrent)
    {
      const computedResistance = Number.parseFloat(voltage) / Number.parseFloat(current);
      const computedPower = Number.parseFloat(voltage) * Number.parseFloat(current);
      setResistance(computedResistance.toFixed(2));
      setPower(computedPower.toFixed(2));
    }
    else if(hasVoltage && hasResistance)
    {
      const computedCurrent = Number.parseFloat(voltage) / Number.parseFloat(resistance);
      const computedPower = Number.parseFloat(voltage) * computedCurrent;
      setCurrent(computedCurrent.toFixed(2));
      setPower(computedPower.toFixed(2));
    }
    else if(hasVoltage && hasPower)
    {
      const computedCurrent = Number.parseFloat(power) / Number.parseFloat(voltage);
      const computedResistance = Number.parseFloat(voltage) / computedCurrent;

      setCurrent(computedCurrent.toFixed(2));
      setResistance(computedResistance.toFixed(2));
    }
    else if(hasCurrent && hasResistance)
    {
      const computedVoltage = Number.parseFloat(current) * Number.parseFloat(resistance);
      const computedPower = computedVoltage * Number.parseFloat(current);
      setVoltage(computedVoltage.toFixed(2));
      setPower(computedPower.toFixed(2));
    }
    else if(hasCurrent && hasPower)
    {
      const computedVoltage = Number.parseFloat(power) / Number.parseFloat(current);
      const computedResistance = computedVoltage / Number.parseFloat(current);
      setVoltage(computedVoltage.toFixed(2));
      setResistance(computedResistance.toFixed(2));
    }
    else if(hasResistance && hasPower)
    {
      const computedVoltage = Math.sqrt(Number.parseFloat(power) * Number.parseFloat(resistance));
      const computedCurrent = Number.parseFloat(power) / computedVoltage;
      setVoltage(computedVoltage.toFixed(2));
      setCurrent(computedCurrent.toFixed(2));
    }
    calculateFuseSize();
  };

  return {
    voltage,
    current,
    resistance,
    power,
    fuseSize,
    setVoltage,
    setCurrent,
    setResistance,
    setPower,
    calculateFuseSize,
    calculateAny,
  };
};

export default useElectricityCalculator;