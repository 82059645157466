// ./hooks/UseAfrCalculator.tsx

import { useState } from 'react';

const StoichiometricAFR = 14.7;

const useAfrCalculator = () => {
  const [lambdaValue, setLambdaValue] = useState(1.0);
  const [afrValue, setAfrValue] = useState(14.7);

  const convertToAfr = () => {
    const afr = lambdaValue * StoichiometricAFR;
    setAfrValue(Number.parseFloat(afr.toFixed(2)));
  };

  const convertToLambda = () => {
    const lambda = afrValue / StoichiometricAFR;
    setLambdaValue(Number.parseFloat(lambda.toFixed(2)));
  };

  return {
    lambdaValue,
    afrValue,
    setLambdaValue,
    setAfrValue,
    convertToAfr,
    convertToLambda,
  };
}

export default useAfrCalculator;