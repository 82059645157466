import React, { ReactNode, useState } from "react";
import OpenWithOutlinedIcon from '@mui/icons-material/OpenWithOutlined';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

type Size = { width: number; height: number };
type Position = { x: number; y: number };

type ResizableProps = {
    height: number;
    minWidth: number;
    children: ReactNode;
};

const Resizable: React.FC<ResizableProps> = ( props: ResizableProps) => {
    const [size, setSize] = useState<Size>({ width: (window.innerWidth / 2), height: props.height});
    const [position, setPosition] = useState<Position>({ x: (window.innerWidth / 2) - (size.width / 2), y: (window.innerHeight / 2) - 80 });
    let moveOffset: Position= { x: 0.0, y: 0.0 };
    let resizeOffset: Position= { x: 0.0, y: 0.0 };
    
    const disableBodyScroll = () => {
        document.body.style.overflow = "hidden";
      }
      
      const enableBodyScroll = () => {
        document.body.style.overflow = "auto";
      }

    const onResizeMouse = (e: React.MouseEvent) => {
        e.preventDefault();
        resizeOffset = {x: (e.clientX - (position.x + size.width)) * -1, y: 0.0 };
        document.addEventListener('mousemove', (onResizeMouseMove as unknown) as EventListener);
        document.addEventListener('mouseup', (onResizeMouseUp as unknown) as EventListener);
      };

      const onResizeTouch = (e: React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        disableBodyScroll();
        resizeOffset = {x: (e.touches[0].clientX - (position.x + size.width)) * -1, y: 0.0 };
        document.addEventListener('touchmove', handleTouchResize, { passive: false });
        document.addEventListener('touchend', handleTouchResizeEnd);
    };

  const onMoveMouse = (e: React.MouseEvent) => {
    e.preventDefault();
    moveOffset = {x: e.clientX - position.x, y: e.clientY - position.y };

    document.addEventListener('mousemove', (onMoveMouseMove as unknown) as EventListener);
    document.addEventListener('mouseup', (onMoveMouseUp as unknown) as EventListener);
  };

  const onMoveTouch = (e: React.TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
    disableBodyScroll();
    moveOffset = {x: e.touches[0].clientX - position.x, y: e.touches[0].clientY - position.y };
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchMoveEnd);  };

  const onResizeMouseMove = (e: globalThis.MouseEvent) => {
    e.preventDefault();
    setSize({
        width: (e.clientX - position.x) + resizeOffset.x,
        height: props.height,
      });
  };

  const handleTouchResize = (e: TouchEvent) => {
    e.preventDefault();
    setSize({
        width: (e.touches[0].clientX - position.x) + resizeOffset.x,
        height: props.height,
      });
  };

  const onResizeMouseUp = () => {
    document.removeEventListener('mousemove', (onResizeMouseMove as unknown) as EventListener);
    document.removeEventListener('mouseup', (onResizeMouseUp as unknown) as EventListener);
  };

  const onMoveMouseMove = (e: globalThis.MouseEvent) => {
    e.preventDefault();
    setPosition(({
        x: e.clientX - moveOffset.x,
        y: e.clientY - moveOffset.y,
    }));
  };

  const handleTouchMove = (e: TouchEvent) => {
    e.preventDefault();
    setPosition(({
        x: e.touches[0].clientX - moveOffset.x,
        y: e.touches[0].clientY - moveOffset.y,
    }));
  };


  const onMoveMouseUp = () => {
    document.removeEventListener('mousemove', (onMoveMouseMove as unknown) as EventListener);
    document.removeEventListener('mouseup', (onMoveMouseUp as unknown) as EventListener);
  };

  const handleTouchMoveEnd = () => {
    enableBodyScroll();
    document.removeEventListener('touchmove', handleTouchMove);
    document.removeEventListener('touchend', handleTouchMoveEnd);
};

const handleTouchResizeEnd = () => {
    enableBodyScroll();
    document.removeEventListener('touchmove', handleTouchResize);
    document.removeEventListener('touchend', handleTouchResizeEnd);
};

  
  return (
    <div
      style={{
        width: size.width, 
        height: props.height,
        position: "absolute", 
        left: position.x, 
        top: position.y,
        border: "solid 1px", 
        resize: "none",
        minWidth: props.minWidth,
      }}
    >
      {props.children}
      <div
        onMouseDown={onMoveMouse}
        onTouchStart={onMoveTouch}
        style={{cursor: "move", position: "absolute", left: 0, top: 0, height: "1.5em", width: "1.5em", backgroundColor: "orange"}}>
            <OpenWithOutlinedIcon sx={{ fontSize: '1.25em' }}/>
        </div>

      <div
        onMouseDown={onResizeMouse}
        onTouchStart={onResizeTouch}
        style={{cursor: "se-resize", position: "absolute", right: 0, bottom: 0, height: "1.5em", width: "1.5em", backgroundColor: "orange"}}>
            <AspectRatioIcon sx={{ fontSize: '1.25em' }}/>
        </div>
    </div>
  );
};

export default Resizable;
