import MotiveTheory from './MotiveTheory';
import { Tabs, Tab } from '@mui/material';
import useSelectedTab from '../hooks/UseSelectedTab';
import AirIcon from '@mui/icons-material/Air';
import SettingsIcon from '@mui/icons-material/Settings';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import ElectricalTheory from './ElectricalTheory';

function Study() {
  const {
    selectedTabIndex,
    tabChanged
  } = useSelectedTab();

  return (
    <div className="App">
      <Tabs
        value={selectedTabIndex}
        onChange={tabChanged}
        variant="scrollable"
        scrollButtons="auto">
        <Tab icon={<ElectricBoltIcon fontSize='medium' />} label="Electrical Theory" />
        <Tab icon={<DeveloperModeIcon fontSize='medium' />} label="Motive Theory" />
      </Tabs>
      {selectedTabIndex === 0 && (<ElectricalTheory />)}
      {selectedTabIndex === 1 && (<MotiveTheory />)}
    </div>
  );
}
export default Study;
