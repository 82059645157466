// ./components/ElectricalTheory.tsx

import React from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check'
import useAnalyticsEventTracker from '../hooks/UseAnalyticsEventTracker';

const ElectricalTheory = () => {
    const gaEventTracker = useAnalyticsEventTracker('MotiveTheory');
    gaEventTracker('pageLoad');

    return (
        <div className='App'>
            <div style={{ textAlign: 'left', margin: '0px 0px 0px 20px', maxWidth: '700px' }}>
            <h1>Electrical Theory for Automotive Technicians</h1>
            <h2>Understanding Electricity</h2>
            <p> Essential to any electrical system, including automobiles, electricity is the flow of electrical power or charge.</p>
            <h2>Charge Movement</h2>
            <p>Automotive electrical systems use a battery as their primary source of electrical power. For traditional combustion engines, electrons move from the negative terminal to the positive terminal of the battery when launching the engine and powering the vehicle's different electrical components.</p>
            <h2>Ohm's Law and its Application</h2>
            <p>A vital part of electrical theory, Ohm's Law states the current (I) through a conductor is directly proportional to the voltage (V) and inversely proportional to resistance (R). It helps automotive technicians calculate and understand electric relationships in the vehicle, stated as I = V/R.</p><p>When testing a circuit, it's important to understand that detecting voltage doesn't necessarily mean that there is enough current to operate a device. <span style={{color:'firebrick', fontWeight:'bold'}}>Even a circuit with low resistance might not allow ample current to flow if there's an issue like corrosion, partial break in the wire, or a loose connection.</span> Therefore, verifying a circuit's ability to carry ample current, not just a presence of voltage, is essential in accurate diagnostics.</p>
            <h2>The Role of the Circuit</h2>
            <p> A circuit allows electricity to flow from the battery, through wires, components, and back to the car battery. This continuous path allows electricity to perform tasks like turning your car's headlights on or cranking the engine.</p>
            <h2>The Role of Switches and Relays</h2>
            <p> Automotive systems incorporate switches and relays to control the flow of electricity to high current devices. Switches enable the users to control the operation of these devices, while relays allow for safe routing of large electric currents. When a switch is turned on, it sends a low current signal to the relay. The relay, in turn, closes its contacts, allowing a higher current to flow from the battery to the specific device.</p>
            <h2>The Role of Fuses and Circuit Breakers</h2>
            <p> Fuses and circuit breakers play crucial roles in automotive electrical systems as protection devices. They are designed to protect the circuit from damage caused by an overload or short circuit. A fuse contains a piece of wire that melts and breaks the circuit if the current exceeds a specific level, while a circuit breaker trips or shuts off the circuit under similar conditions. This arrangement ensures that the higher current does not have to flow through the control switch, which reduces the risk of electrical faults and potential fire hazards.</p>
            <h2>Essential Electrical Components</h2>
            <p>Various electrical components form part of an automobile. For combustion engine vehicles, these essential units are the battery, alternator, starter, lights, sensors, and various control units. An EV or electric vehicle would also include electric motors and large battery packs.</p>
            <h2>Wiring and Grounding</h2>
            <p>Automotive vehicles utilize wiring and grounding to manage electricity flow. The vehicle's frame or chassis frequently acts as a ground, providing a return path for electrons.</p>
            <h2>Safety Precautions</h2>
            <p>Safety is paramount when dealing with electrical components. Always disconnect the power source before working on the system. Using personal protective equipment like rubber gloves and safety goggles is essential to prevent direct contact with live wires or components.</p>
            <h2>Evolution of the Electric Vehicle (EV)</h2>
            <p>Traditional internal combustion engines use gasoline or diesel fuel to generate power, which turns the alternator, producing electrical power for the rest of the vehicle. In contrast, electric vehicles (EVs) draw electricity from the power grid, storing it in high-capacity, rechargeable battery packs.  Electric vehicles will commonly have:</p>
            <ul>
                <li>
                    <p>Solid-State Relays (SSR): These are advanced versions of electromechanical relays. SSRs are faster, have no physical contacts that can wear out, they create less electrical noise, and are more efficient.</p>
                </li>
                <li>
                    <p>Power Electronics: Power electronic components such as transistors, silicon-controlled rectifiers (SCRs), and other solid-state devices are used to control and manage the high power transfers in electronic vehicles, such as from the battery to the electric motors.</p>
                </li>
                <li>
                    <p>Controllers: In EVs, controllers play a crucial role in handling and modulating the power delivery from the battery to the electric motor based on the driver's inputs.</p>
                </li>
                <li>
                    <p>Software: Modern EVs use sophisticated software to control the electrical systems within the vehicle. This allows for more flexibility, efficiency, and can offer features like regenerative braking.</p>
                </li>
            </ul>
            <p>Understanding the basics of electrical theory is essential for automotive technicians as they work on increasingly complex and diverse vehicle electrical systems.</p>
            </div>
            </div>
    );
};

export default ElectricalTheory;