import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from './errorPage';

const router = createBrowserRouter([
  {
    path: "/",
    index: true,
    element: <App selected="scope" />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/pressure-waveform-overlays",
    element: <App selected="scope" />,
  },
  {
    path: "/vin-decoder",
    element: <App selected="vin" />,
  },
  {
    path: "/automotive-calculators",
    element: <App selected="calc" />,
  },
  {
    path: "/automotive-study",
    element: <App selected="study" />,
  },
  {
    path: "/automotive-study/:itemName",
    element: <App selected="study" />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
