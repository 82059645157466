// ./components/MotiveTheory.tsx

import React from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check'
import useAnalyticsEventTracker from '../hooks/UseAnalyticsEventTracker';

const MotiveTheory = () => {
    const gaEventTracker = useAnalyticsEventTracker('MotiveTheory');
    gaEventTracker('pageLoad');

    return (
        <div className='App'>
            <div style={{ textAlign: 'left', margin: '0px 0px 0px 20px', maxWidth: '700px' }}>
            <h1>Motive Power Theory</h1>
            <p>Motive Power Theory focuses on the process through which different types of engines convert fuel into energy.</p>
            <h2>Introduction to Thermodynamics</h2>
            <p>Thermodynamics is a branch of physics that is centered on energy transformations vital for the engine's operation. It highlights the conversion of thermal energy into mechanical energy, which forms the base of power generation in engines. The observable changes such as alterations in temperature, pressure, and volume within the engine's cylinders make this possible.</p>
            <h2>Volume, Pressure and Temperature</h2>
            <p>When there is a reduction in volume during compression, both pressure and temperature increase significantly. The increase in temperature serves as a precursor for the ignition of the air-fuel mixture in gasoline engines or provokes direct ignition of diesel fuel. High temperatures yield high energy, and well-designed engines aim to optimally convert this heat energy into mechanical work.</p>
            <h2>Force, Work, and Power</h2>
            <p>The primary measurable outputs of an engine include force, work, and power. Force is generated through the expanding gases pushing down the piston. Work happens when that force is used to move the piston, and power is the work done per unit time. Horsepower denotes an engine's power output capability.</p>
            <h2>Types of Engine Ignition</h2>
            <p>There are two types of engine ignitions:</p>
            <ol>
                <li>
                    <p>Spark Ignition in Gasoline (Petrol) Engines: Intake charge of a mixture of air and fuel. A spark plug ignites this mixture, creating a flame that travels across the cylinder and pushes the piston down, generating power.</p>
                </li>
                <li>
                    <p>Compression Ignition in Diesel Engines: Clean air is induced into the cylinder during the intake stroke. The fuel is injected towards the end of the compression stroke and the high temperature due to the compressed air ignites the fuel, pushing down the piston and generating power.</p>
                </li>
            </ol>
            <h2>The Otto Cycle</h2>
            <p>Named after Nikolaus Otto, the Otto Cycle is a theoretical model for the four-stroke internal combustion engine.  It offers a practical application of thermodynamic principles and includes stages that drive the conversion of thermal energy into useful work.  The Otto Cycle undergoes a 720-degree rotation of the crankshaft. That means two full turns, encompassing all four strokes in the cycle.  These stages are:</p>
            <ol>
                <li>
                    <p>Intake Stroke: In the first 180-degree rotation of the crankshaft, the piston moves down, and the intake valve opens to allow an air-fuel mixture (for gasoline engines) or only air (for diesel engines) mixture into the cylinder. The crankshaft rotates 180 degrees during this phase.</p>
                </li>
                <li>
                    <p>Compression Stroke: Intake valve closes as the piston moves upward, compressing the air-fuel mixture (gasoline engine) or air (diesel engine). For a gasoline engine, just before the piston reaches the top of the stroke, the spark plug ignites the compressed air-fuel mixture. In a diesel engine, fuel is injected into the compressed hot air, initiating spontaneous combustion. The crankshaft completes another 180 degrees rotation.</p>
                </li>
                <li>
                    <p>Power (Combustion) Stroke: The combustion of the air-fuel mixture or diesel fuel forces the piston down, creating power that drives the crankshaft another 180 degrees. This is the stroke where work is done on the piston by the high-pressure gas that aids in rotating the crankshaft converting this pressure into mechanical energy.  The crankshaft completes another 180 degrees rotation.</p>
                </li>
                <li>
                    <p>Exhaust Stroke: The exhaust valve opens as the piston begins a second upwards movement, expelling the combustion gases (exhaust). This final stroke completes the 720 degrees rotation of the crankshaft.</p>
                </li>
            </ol>
            <h2>Additional Factors Affecting Engine Performance</h2>
            <p>Factors that affect engine performance include valve timing, spark timing, type of fuel used, effective cooling and lubrication systems, and emission reductions.</p>
            <p>In conclusion, while all internal combustion engines share the same basic cycles, different designs, and technologies, they result in variations in power output, efficiency, and emissions.</p>
            </div>
            </div>
    );
};

export default MotiveTheory;