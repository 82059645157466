// ./hooks/UseAirFlowCalculator.tsx

import { useState } from 'react';

const useAirFlowCalculator = () => {
  const [gramsPerSec, setGramsPerSec] = useState(5.3);
  const [milligramsPerStroke, setMilligramsPerStroke] = useState(90.34);
  const [rpm, setRpm] = useState(880);
  const [cylinders, setCylinders] = useState(8);

  const toMilligramsPerStroke = () => {
    const milligrams = (gramsPerSec * 1000 * 60) / ((rpm / 2) * cylinders);
    setMilligramsPerStroke(Number.parseFloat(milligrams.toFixed(2)));
  };

  const toGramsPerSec = () => {
    const grams = (milligramsPerStroke * (rpm / 2) * cylinders) / (60 * 1000);
    setGramsPerSec(Number.parseFloat(grams.toFixed(2)));
  };

  return {
    gramsPerSec,
    milligramsPerStroke,
    rpm,
    cylinders,
    setGramsPerSec,
    setMilligramsPerStroke,
    setRpm,
    setCylinders,
    toMilligramsPerStroke,
    toGramsPerSec,
  };
}

export default useAirFlowCalculator;