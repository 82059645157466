// ./hooks/UseEffectiveGearRatioCalculator.tsx

import { useState } from 'react';

const useEffectiveGearRatioCalculator = () => {
  const [originalTireDiameter, setOriginalTireDiameter] = useState(27.0);
  const [newTireDiameter, setNewTireDiameter] = useState(31.0);
  const [actualGearRatio, setActualGearRatio] = useState(3.04);
  const [effectiveGearRatio, setEffectiveGearRatio] = useState(0.0);

  const calculateEffectiveGearRatio = () => {
    const effectiveRatio = (originalTireDiameter / newTireDiameter) * actualGearRatio;
    setEffectiveGearRatio(Number.parseFloat(effectiveRatio.toFixed(2)));
  };

  return {
    originalTireDiameter,
    newTireDiameter,
    actualGearRatio,
    effectiveGearRatio,
    setOriginalTireDiameter,
    setNewTireDiameter,
    setActualGearRatio,
    calculateEffectiveGearRatio,
  };
}

export default useEffectiveGearRatioCalculator;