// UseSelectedTab.ts
import { useState } from 'react';

const useSelectedTab = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    
  const tabChanged = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };
   
  return { selectedTabIndex, tabChanged };
};

export default useSelectedTab;