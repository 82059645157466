import useEnginePhaseSimulator from '../hooks/UseEnginePhaseSimulator'
import { Button, FormControl, Grid, Paper, Slider, Stack, TextField, Input, styled } from '@mui/material';
import Resizable from './Resizable';
import { useEffect, useState } from 'react';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import useAnalyticsEventTracker from '../hooks/UseAnalyticsEventTracker';
import { red } from '@mui/material/colors';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: 0,
    minHeight: '1.0em',
    maxHeight: '1.0em',
}));

function WaveformOverlay() {
    const gaEventTracker = useAnalyticsEventTracker('WaveformOverlay');

    const { stepEngine, CylinderStates, CylinderFiringSequence, setCylinderFiringSequence, setCurrentCrankshaftRotationAngle, engineCycles } = useEnginePhaseSimulator();

    const stepDisabled = ([4, 5, 6, 8, 10].includes(CylinderFiringSequence.length) === false);

    const setFiringOrder = (value: string) => {
        let firingOrder = Array<number>();
        for (let i = 0; i < value.length; i++) {
            firingOrder.push(Number.parseInt(value[i]));
        }
        setCylinderFiringSequence(firingOrder);
        setCurrentCrankshaftRotationAngle((value.length === 6) ? 30 : 0);
    }

    const [file, setFile] = useState("");
    
    function fileUploaded(e: any) {
        gaEventTracker('fileUploaded');
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const handlePasteEvent = (e: ClipboardEvent) => {
        var items = e.clipboardData?.items;
        if(!items) {
            return;
        }

        for (var i = 0; i < items.length; i++) {
          if (items[i].kind === 'file' && items[i].type.startsWith('image/')) {
            var blob = items[i].getAsFile();
            if(blob) {
                var url = URL.createObjectURL(blob);
                setFile(url); //update the state with the new image url
                gaEventTracker('fileUploaded');
            }
            return;
          }
        }
      };

    const [opacity, setOpacity] = useState<number>(0.5);

    const opacityChanged = (event: Event, newValue: number | number[]) => {
        setOpacity(newValue as number);
    };

    useEffect(() => {
        document.addEventListener('paste', handlePasteEvent)
        return () => {
            document.removeEventListener('paste', handlePasteEvent)
        }
     }, [])
     
    return (
        <div className="App">
            <h3>Pressure Waveform Overlays</h3>
            <div className="w99" style={{ padding: '0px 0px 0px 0px' }}>
                <FormControl sx={{ m: 1, minWidth: 140, maxWidth: 140 }}>
                    <TextField id='firing-order'
                        type="number"
                        value={CylinderFiringSequence.join('')}
                        onChange={e => setFiringOrder(e.target.value)}
                        onKeyDown={e => ["Enter", "NumpadEnter"].includes(e.code) ? stepEngine() : null}
                        placeholder="Cylinder Firing Order"
                        label="Cylinder Firing Order"
                    />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 140, maxWidth: 140 }}>
                    <Button disabled={stepDisabled} variant="outlined" color="primary" aria-label="Rotate" size="large" onClick={stepEngine}>Rotate</Button>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 150, maxWidth: 150 }}>
                    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                        <VisibilityOffOutlinedIcon />
                        <Slider aria-label="Opacity" value={opacity} onChange={opacityChanged} 
                            min={0.025}
                            max={0.8} step={0.025} />
                        <VisibilityOutlinedIcon />
                    </Stack>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 250, maxWidth: 250 }}>
                    <Button
                        variant="outlined" color="primary" 
                        component="label"
                        >
                        <Input type="file" onChange={fileUploaded} />
                    </Button>
                </FormControl>
            </div>
            <div className="w99" style={{ padding: '5px 0px 0px 0px' }}>
                Enter Cylinder Firing Order.  Click Rotate to Sync.  4, 5, 6, 8, 10 Cylinders Supported (use 0 for 10).<br/>
                Upload -OR- Copy and Paste in Waveform.  Move and Resize Overlay to align with 0-720 degrees.<br/>
                {engineCycles.map((ec) => <span><span style={{backgroundColor: ec.colorRepresentation}}>&nbsp;&nbsp;&nbsp;</span>&nbsp;{ec.name}&nbsp;&nbsp;&nbsp;</span>)}
            </div>
            <div style={{backgroundImage: `url(${file})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 50%', margin: '0 auto', minWidth:'70%', maxWidth:'90%', width: window.innerWidth * .9, minHeight: '300px', maxHeight: '70%', height: window.innerHeight * .7}}>
                &nbsp;
            </div>
            <Resizable 
                height={CylinderStates.length * (Number.parseFloat(getComputedStyle(document.body).fontSize) * 2.34)}
                minWidth={140}
                >
                {CylinderStates.map(cyl =>
                    <Grid container spacing={0} className="full" style={{opacity: opacity, }}>
                        <Grid xs={(12 / (cyl.CylinderPhases.length + 1))}>
                            <Item style={{ backgroundColor: 'whitesmoke' }}>{cyl.Number}</Item>
                        </Grid>
                        {cyl.CylinderPhases.map(ph => <Grid item xs={(12 / (cyl.CylinderPhases.length + 1))}>
                            <Item style={{ backgroundColor: ph.colorRepresentation }}></Item>
                        </Grid>)}
                    </Grid>
                )}
            </Resizable>
        </div>
    );
}
export default WaveformOverlay;
