import React from 'react';
import './App.css';
import WaveformOverlay from './components/WaveformOverlay';
import WavesOutlinedIcon from '@mui/icons-material/WavesOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Calculators from './components/Calculators';
import VinDecoder from './components/VinDecoder';
import ReactGA from 'react-ga';
import useAnalyticsEventTracker from './hooks/UseAnalyticsEventTracker';
import Study from './components/Study';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { Link, useLocation } from 'react-router-dom';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className='TabPanel'
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, width: '100%', padding: 0, margin: 0, }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

interface AppProps {
  selected?: string;
}

function App(props: AppProps) {
  const TRACKING_ID = "G-DGBQM6G5D7";
  ReactGA.initialize(TRACKING_ID);

  const gaEventTracker = useAnalyticsEventTracker('App');
  gaEventTracker('pageLoad');

  const { pathname } = useLocation();
  const passedInTab = () => {
    switch(pathname) {
      case "/automotive-calculators":
        return 0;
      case "/pressure-waveform-overlays":
        return 1;
      case "/automotive-study":
        return 2;
      case "/vin-decoder":
        return 3;
      default:
        return 1;
    }
  }
  
  const [value, setValue] = React.useState(passedInTab);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    gaEventTracker('tabChanged');
    setValue(newValue);
  };

  return (
    <div className="App" style={{ backgroundColor: "#00000000" }}>
      <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%', width: '100%', padding: 0, margin: 0, }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Navigation"
          sx={{ borderRight: 1, borderColor: 'divider', minWidth: '85px', maxWidth: '125px', width: '85px',  padding: 0, margin: 0, }}
        >
          <Tab icon={<CalculateOutlinedIcon/>} label="Calc" component={Link} to="/automotive-calculators" value={0} {...a11yProps(0)} />
          <Tab icon={<WavesOutlinedIcon/>} label="Scope" component={Link} to="/pressure-waveform-overlays" value={1} {...a11yProps(1)} />
          <Tab icon={<AutoStoriesIcon/>} label="Study" component={Link} to="/automotive-study" value={2} {...a11yProps(2)} />
          <Tab icon={<FeedOutlinedIcon/>} label="VIN" component={Link} to="/vin-decoder" value={3} {...a11yProps(3)} />
        </Tabs>
        {pathname=="/automotive-calculators" && <Calculators/>}
        {pathname=="/" && <WaveformOverlay/>}
        {pathname=="/pressure-waveform-overlays" && <WaveformOverlay/>}
        {pathname=="/automotive-study" && <Study/>}
        {pathname=="/vin-decoder" && <VinDecoder/>}
      </Box>
      <br/>&copy; Hunt's Workshop {new Date().getFullYear()}.  Apps on this site are experimental.  Use at your own risk.  No warranties express or implied.  Intended for Desktop use.  Please submit bugs and/or feature requests via <a href="https://huntsworkshop.com/contact-me">Contact Me</a>.
    </div>
  );
}
export default App;