// ./components/ElectricityCalculator.tsx

import React from 'react';
import useElectricityCalculator from '../hooks/UseElectricityCalculator';
import { Button, FormControl, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check'

const ElectricityCalculator = () => {
  const {
    voltage,
    current,
    resistance,
    power,
    fuseSize,
    setVoltage,
    setCurrent,
    setResistance,
    setPower,
    calculateAny,
  } = useElectricityCalculator();

  const valuesProvided = () => {
    let i = 0;
    if(voltage > '') i+=1;
    if(current > '') i+=1;
    if(resistance > '') i+=1;
    if(power > '') i+=1;
    //Only 2 values allowed for calculation
    return(i);
  }

  return (
    <div className='App'>
      <div>Electricity Calculator</div>
      
      <p>
        Enter any TWO values to calculate the remaining values.
      </p>

      <FormControl  sx={{ m: 1, minWidth: 200 }}>
        <TextField 
          type="number" 
          value={voltage} 
          onChange={e => setVoltage(e.target.value)} 
          placeholder="Voltage (V)"
          label="Voltage (V)"
        />
      </FormControl><br/>
      <FormControl  sx={{ m: 1, minWidth: 200 }}>
        <TextField 
            type="number" 
            value={current} 
            onChange={e => setCurrent(e.target.value)} 
            placeholder="Current (A)"
            label="Current (A)"
        />
      </FormControl><br/>

      <FormControl  sx={{ m: 1, minWidth: 200 }}>
        <TextField 
          type="number" 
          value={resistance} 
          onChange={e => setResistance(e.target.value)} 
          placeholder="Resistance (Ω)"
          label="Resistance (Ω)"
        />
      </FormControl><br/>

    <FormControl  sx={{ m: 1, minWidth: 200 }}>
    <TextField 
        type="number" 
        value={power} 
        onChange={e => setPower(e.target.value)} 
        placeholder="Power (W)"
        label="Power (W)"
        />
    </FormControl><br/>

    <FormControl  sx={{ m: 1, minWidth: 200 }}>
        <Button disabled={valuesProvided() !== 2} variant="outlined" color="primary" aria-label="Calculate" size="large" startIcon={<CheckIcon fontSize="inherit"/>} onClick={calculateAny}>{valuesProvided() === 2 ? "Calculate" : valuesProvided() < 2 ? "Calculate: Requires 2 values" : "Calculate: Only 2 values allowed"}</Button>
      </FormControl><br/>

      {fuseSize > '' && <p>Fuse Size (A): {fuseSize}</p>}
    </div>
  );
};

export default ElectricityCalculator;