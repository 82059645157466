import React, { useState } from 'react';
import axios from 'axios';
import { VehicleInfo, VehicleResult } from '../models/VinDecodeResponse';
import { FormControl, TextField, Button } from '@mui/material';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';

function VinDecoder() {
  const [vin, setVin] = useState("");
  const [vinData, setVinData] = useState<VehicleInfo|null>(null);
  
  const handleVinChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setVin(e.target.value);
  }

  const decodeVin = async () => {
    try {
        const response = await axios.get<VehicleResult>(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValues/${vin}?format=json`);
        setVinData(response.data.Results[0]);
    } catch (err) {
      console.error(`Error: ${err}`);
    }
  }
  
  return (
    <div className="App">
      <h3>VIN Decoder</h3>
      <FormControl  sx={{ m: 1, minWidth: 200 }}>
            <TextField 
                type="text" 
                value={vin} 
                onChange={handleVinChange} 
                placeholder="Enter VIN"
            />
        </FormControl><br/>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <Button variant="outlined" color="primary" aria-label="Decode VIN" size="large" startIcon={<FeedOutlinedIcon fontSize="inherit" />} onClick={decodeVin}>Decode VIN</Button>
      </FormControl><br/>

      {vinData && (
        <div>
          <p>Year: {vinData.ModelYear}</p>
          <p>Make: {vinData.Make}</p>
          <p>Model: {vinData.Model}</p>
          <p>Series: {vinData.Series}</p>
          <p>Cylinders: {vinData.EngineCylinders}</p>
          <p>L: {Number.parseFloat(vinData.DisplacementL).toFixed(1)}</p>
          <p>CI: {vinData.DisplacementCI}</p>
          <p>HP: {vinData.EngineHP}</p>
        </div>
      )}

    </div>
  );
}

export default VinDecoder;