// ./components/EngineDisplacementCalculator.tsx

import React from 'react';
import useEngineDisplacementCalculator from '../hooks/UseEngineDisplacementCalculator';
import { Button, FormControl, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check'

const EngineDisplacementCalculator = () => {
  const {
    bore,
    stroke,
    cylinders,
    displacement,
    setBore,
    setStroke,
    setCylinders,
    calculateDisplacement,
  } = useEngineDisplacementCalculator();

  return (
    <div className='App'>
      <div>Engine Displacement Calculator</div>
      <p>
        Engine Displacement can be calculated using the formula:  bore x bore x stroke x 0.7854 x number of cylinders
      </p>
      <FormControl  sx={{ m: 1, minWidth: 200 }}>
        <TextField 
          type="number" 
        value={bore} 
        onChange={e => setBore(Number.parseFloat(e.target.value))} 
        placeholder="Bore"
        label="Bore"
      />
      </FormControl><br/>
      <FormControl  sx={{ m: 1, minWidth: 200 }}>
        <TextField 
            type="number" 
        value={stroke} 
        onChange={e => setStroke(Number.parseFloat(e.target.value))} 
        placeholder="Stroke"
        label="Stroke"
      />
      </FormControl><br/>
      <FormControl  sx={{ m: 1, minWidth: 200 }}>
        <TextField 
            type="number" 
        value={cylinders} 
        onChange={e => setCylinders(Number.parseInt(e.target.value, 10))} 
        placeholder="Number of Cylinders"
        label="Number of Cylinders"
      />
      </FormControl><br/>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <Button variant="outlined" color="primary" aria-label="Calculate Displacement" size="large" startIcon={<CheckIcon fontSize="inherit" />} onClick={calculateDisplacement}>Calculate Displacement</Button>
      </FormControl><br/>
      {displacement > 0 && <p>Engine Displacement: {displacement}</p>}
    </div>
  );
};

export default EngineDisplacementCalculator;