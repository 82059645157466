// ./hooks/UseEngineDisplacementCalculator.tsx

import { useState } from 'react';

const useEngineDisplacementCalculator = () => {
  const [bore, setBore] = useState(4.0);
  const [stroke, setStroke] = useState(3.25);
  const [cylinders, setCylinders] = useState(8.0);
  const [displacement, setDisplacement] = useState(0.0);

  const calculateDisplacement = () => {
    const computedDisplacement = bore * bore * stroke * 0.7854 * cylinders;
    setDisplacement(Number.parseFloat(computedDisplacement.toFixed(2)));
  };

  return {
    bore,
    stroke,
    cylinders,
    displacement,
    setBore,
    setStroke,
    setCylinders,
    calculateDisplacement,
  };
}

export default useEngineDisplacementCalculator;