// ./components/AirFlowCalculator.tsx

import React from 'react';
import useAirFlowCalculator from '../hooks/UseAirFlowCalculator';
import { FormControl, TextField, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check'

const AirFlowCalculator = () => {
  const {
    gramsPerSec,
    milligramsPerStroke,
    rpm,
    cylinders,
    setGramsPerSec,
    setMilligramsPerStroke,
    setRpm,
    setCylinders,
    toMilligramsPerStroke,
    toGramsPerSec,
  } = useAirFlowCalculator();

  return (
    <div className="App">
      <div>Airflow</div>
      <p>
        Milligrams per stroke (mg/st) can be calculated from grams per second (g/s) using the formula: (grams per second * 1000 * 60) / (rpm * cylinders / 2)<br />
        <br />
        This formula only works for four-stroke engines.<br />
      </p>

      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          type="number"
          value={cylinders}
          onChange={e => setCylinders(Number.parseInt(e.target.value, 10))}
          placeholder="Cylinders"
          label="Cylinders"
        />
      </FormControl><br/>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          type="number"
          value={rpm}
          onChange={e => setRpm(Number.parseInt(e.target.value, 10))}
          placeholder="RPM"
          label="RPM"
        />
      </FormControl><br/>
      <br/>
      <br/>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          type="number"
          value={gramsPerSec}
          onChange={e => setGramsPerSec(Number.parseFloat(e.target.value))}
          placeholder="g/s"
          label="g/s"
        />
      </FormControl><br/>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <Button variant="outlined" color="primary" aria-label="Calculate mg/st" size="large" startIcon={<CheckIcon fontSize="inherit" />} onClick={toMilligramsPerStroke}>Calculate mg/st</Button>
      </FormControl><br/>
      <br/>
      <br/>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <TextField
          type="number"
          value={milligramsPerStroke}
          onChange={e => setMilligramsPerStroke(Number.parseFloat(e.target.value))}
          placeholder="mg/stroke"
          label="mg/stroke"
        />
      </FormControl><br/>

      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <Button variant="outlined" color="primary" aria-label="Calculate g/s" size="large" startIcon={<CheckIcon fontSize="inherit" />} onClick={toGramsPerSec}>Calculate g/s</Button>
      </FormControl><br/>

    </div>
  );
};

export default AirFlowCalculator;