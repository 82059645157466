import AfrCalculator from './AfrCalculator';
import AirFlowCalculator from './AirFlowCalculator';
import EffectiveGearRatioCalculator from './EffectiveGearRatioCalculator';
import EngineDisplacementCalculator from './EngineDisplacementCalculator';
import ElectricityCalculator from './ElectricityCalculator';
import { Tabs, Tab } from '@mui/material';
import useSelectedTab from '../hooks/UseSelectedTab';
import AirIcon from '@mui/icons-material/Air';
import TuneIcon from '@mui/icons-material/Tune';
import SettingsIcon from '@mui/icons-material/Settings';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';

function Calculators() {
  const {
    selectedTabIndex,
    tabChanged
  } = useSelectedTab();

  return (
    <div className="App">
      <Tabs
        value={selectedTabIndex}
        onChange={tabChanged}
        variant="scrollable"
        scrollButtons="auto">
        <Tab icon={<AirIcon fontSize='medium' />} label="Air" />
        <Tab icon={<TuneIcon fontSize='medium' />} label="Air/Fuel" />
        <Tab icon={<SettingsIcon fontSize='medium' />} label="Gears" />
        <Tab icon={<DeveloperModeIcon fontSize='medium' />} label="Displ" />
        <Tab icon={<ElectricBoltIcon fontSize='medium' />} label="Elec" />
      </Tabs>
      {selectedTabIndex === 0 && (<AirFlowCalculator />)}
      {selectedTabIndex === 1 && (<AfrCalculator />)}
      {selectedTabIndex === 2 && (<EffectiveGearRatioCalculator />)}
      {selectedTabIndex === 3 && (<EngineDisplacementCalculator />)}
      {selectedTabIndex === 4 && (<ElectricityCalculator />)}
    </div>
  );
}
export default Calculators;
